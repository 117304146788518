<template>
  <div class="TeacherOtherNews">
    <el-form ref="form" :model="form" label-width="135px">
      <el-form-item label="工作经历">
        <el-input
          v-model="form.experience"
          type="textarea"
          :rows="5"
          placeholder="请输入工作经历"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="认证证书">
        <el-input
          v-model="form.credentials"
          type="textarea"
          :rows="5"
          placeholder="请输入认证证书"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="主讲课程">
        <el-input
          v-model="form.offlineCourses"
          type="textarea"
          :rows="5"
          placeholder="请输入主讲课程"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="主持项目">
        <el-input v-model="form.projects" type="textarea" :rows="5" placeholder="请输入主持项目">
        </el-input>
      </el-form-item>
      <el-form-item label="出版作品">
        <el-input
          v-model="form.publications"
          type="textarea"
          :rows="5"
          placeholder="请输入出版作品"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="服务企业">
        <el-input
          v-model="form.serviceEnterprise"
          type="textarea"
          :rows="5"
          placeholder="请输入服务企业"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="goBack">上一步</el-button>
        <el-button type="primary" :loading="loading" class="submitBnt" @click="submit"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
    <teacher-tag-dialog ref="teacherTagDialog" @tagChange="tagChange"></teacher-tag-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { teacherCreate, teacherUpdate } from '@/api/teacher'
import { createNamespacedHelpers } from 'vuex'
const { mapMutations, mapState } = createNamespacedHelpers('user')
import TeacherTagDialog from './TeacherTagDialog'
export default {
  components: { TeacherTagDialog },
  props: {
    teacherBaseInfo: {
      type: Object,
      default: () => {},
    },
    teacherId: {
      type: String,
      default: '',
    },
    teacherOtherInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      loading: false,
    }
  },
  computed: {
    ...mapState(['step']),
  },
  watch: {
    teacherOtherInfo: {
      handler(val) {
        this.form = {
          ...val,
        }
        this.teacherTagList = val.teacherTagList
      },
      deep: true, // 深度监听
      immediate: true, // 初次监听即执行
    },
  },
  created() {},
  methods: {
    ...mapMutations({ stepPage: 'SET_STEP' }),
    goBack() {
      this.stepPage(2)
      this.$emit('back', this.teacherBaseInfo)
    },
    submit() {
      this.teacherId ? this.update() : this.create()
    },
    removeTag(item) {
      const i = this.form.teacherTagIdList.indexOf(item.tagId)
      this.teacherTagList.splice(i, 1)
      this.form.teacherTagIdList.splice(i, 1)
    },
    selectTag() {
      this.$refs.teacherTagDialog.show(this.teacherId ? this.teacherTagList : [])
    },
    tagChange({ teacherTagList, teacherTagIdList }) {
      if (teacherTagIdList.length) {
        this.$refs.teacherTag.clearValidate()
      }
      this.form.teacherTagIdList = teacherTagIdList
      this.teacherTagList = teacherTagList
    },
    create() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(
            teacherCreate({
              teacherBaseInfo: {
                ...this.teacherBaseInfo,
                teacherPicList: [this.teacherBaseInfo.teacherPicList],
              },
              teacherOtherInfo: this.form,
              teacherId: this.teacherId,
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          this.$router.push('/teacher/list')
          setTimeout(() => {
            this.stepPage(2)
          }, 100)
        }
      })
    },
    update() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          this.teacherBaseInfo.teacherPicList = [this.teacherBaseInfo.teacherPicList]
          const [, err] = await to(
            teacherUpdate({
              teacherBaseInfo: this.teacherBaseInfo,
              teacherOtherInfo: this.form,
              teacherId: this.teacherId,
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('修改成功')
          this.$router.push('/teacher/list')
          setTimeout(() => {
            this.stepPage(2)
          }, 100)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.TeacherOtherNews {
  .submitBnt {
    margin-left: 100px;
  }
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 120px;
    .el-upload {
      width: 120px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 120px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
  .teachers {
    li {
      box-sizing: border-box;
      float: left;
      position: relative;
      cursor: pointer;
      min-width: 80px;
      padding: 0 13px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      color: #333333;
      margin-right: 25px;
      margin-bottom: 10px;
      .close {
        position: absolute;
        width: 14px;
        height: 14px;
        right: -5px;
        top: -5px;
      }
    }
  }
}
</style>
