<template>
  <div class="teacher-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ teacherId ? '修改讲师' : '添加讲师' }}</span>
      </div>
      <el-form v-if="step == 0" ref="form" :model="form" label-width="108px" :rules="rules">
        <el-row>
          <el-col :span="8">
            <el-form-item label="讲师姓名" prop="teacherName">
              <el-input
                v-model="form.teacherName"
                placeholder="请输入讲师姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="常驻地区" prop="addressCode">
              <el-cascader
                ref="myCascader"
                v-model="form.addressCode"
                :options="total"
                :props="{
                  value: 'label',
                }"
                placeholder="请选择常驻地区"
                @change="handleAddressChange"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="联系邮箱" prop="email">
              <el-input
                v-model="form.email"
                placeholder="请输入邮箱"
                clearable
                @blur="checkEmail"
              ></el-input>
            </el-form-item>
            <el-form-item label="讲师职称">
              <el-select v-model="form.teacherRank" placeholder="请选择讲师职称">
                <el-option
                  v-for="item in rankOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="授课报价" prop="price">
              <el-input
                v-model="form.lowestOffer"
                placeholder="请输入价格"
                clearable
                class="coursePrice"
                @blur="price(1)"
              ></el-input>
              ~
              <el-input
                v-model="form.highestOffer"
                placeholder="请输入价格"
                clearable
                class="coursePrice"
                @blur="price(2)"
              ></el-input>
              <span style="margin-left:12px">元</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="讲师性别" prop="gender">
              <!-- <el-radio-group v-model="form.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group> -->
              <el-select v-model="form.gender" placeholder="请选择讲师性别" clearable>
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="毕业院校" prop="college">
              <el-input v-model="form.college" placeholder="请输入毕业院校" clearable></el-input>
            </el-form-item>
            <el-form-item label="讲师称号" prop="title">
              <el-input v-model="form.title" placeholder="请输入讲师称号" clearable></el-input>
            </el-form-item>
            <el-form-item label="研究所">
              <el-input v-model="form.institutes" placeholder="请输入研究所" clearable></el-input>
            </el-form-item>
            <el-form-item label="是否对外发布">
              <!-- <el-switch v-model="form.accessLevel" active-value="PUBLIC" inactive-value="PRIVATE">
              </el-switch> -->
              <el-radio-group v-model="form.accessLevel">
                <el-radio label="PUBLIC">是</el-radio>
                <el-radio label="PRIVATE">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期" prop="birthday">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                placeholder="请选择出生日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="最高学历" prop="teacherQualification">
              <el-select v-model="form.teacherQualification" placeholder="请选择最高学历">
                <el-option
                  v-for="item in qualificationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="讲师性质">
              <el-select v-model="form.teacherSource" placeholder="请选择讲师性质" clearable>
                <el-option label="社会讲师" :value="1"></el-option>
                <el-option label="大学讲师" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学术头衔">
              <el-input v-model="form.titles" placeholder="请输入学术头衔" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="讲师分类" prop="teacherMajorIdList">
          <el-cascader
            v-model="form.teacherMajorIdList"
            :options="majorldOptions"
            placeholder="请选择讲师分类"
            :props="{
              value: 'categoryId',
              label: 'name',
            }"
          ></el-cascader>
        </el-form-item>
        <el-form-item ref="teacherTag" label="专长标签" prop="teacherTagIdList">
          <ul v-if="teacherTagList.length" class="teachers">
            <li v-for="item in teacherTagList" :key="item.tagId" class="teacher">
              <span>{{ item.tagName }}</span>
              <img src="@/assets/common/close_icon.png" class="close" @click="removeTag(item)" />
            </li>
          </ul>
          <el-button @click="selectTag">请选择专长</el-button>
        </el-form-item>
        <el-form-item ref="pic" label="上传头像" prop="teacherPicList">
          <upload-image
            ref="uploadImage"
            v-model="form.teacherPicList"
            :upload-img="$refs.pic"
          ></upload-image>
        </el-form-item>
        <el-form-item ref="attachments" label="课程包">
          <upload-file v-model="form.coursePackageUris" :file-type="'.rar,.zip'"> </upload-file>
        </el-form-item>
        <!-- <el-form-item label="讲师所属">
          <el-radio-group v-model="form.teacherBelong">
            <el-radio :label="3">外聘</el-radio>
            <el-radio :label="6">自有</el-radio>
          </el-radio-group>
        </el-form-item>
        -->
        <el-form-item label="讲师特点">
          <el-input v-model="form.feature" type="textarea" :rows="5" placeholder="请输入讲师特点">
          </el-input>
        </el-form-item>
        <el-form-item label="讲师简历描述" prop="teacherDetails">
          <tinymce ref="editor" v-model="form.teacherDetails"></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit">下一步</el-button>
        </el-form-item>
      </el-form>
      <TeacherOtherNews
        v-if="step == 1"
        :teacher-base-info="form"
        :teacher-other-info="teacherOtherInfo"
        :teacher-id="teacherId"
        @back="back"
      ></TeacherOtherNews>
    </el-card>
    <teacher-tag-dialog ref="teacherTagDialog" @tagChange="tagChange"></teacher-tag-dialog>
  </div>
</template>
<script>
import UploadFile from '@/components/UploadFile'
import { createNamespacedHelpers } from 'vuex'
const { mapMutations, mapState } = createNamespacedHelpers('user')
import { getTeacherTypeSelect, teacherCreate, teacherDetail, teacherUpdate } from '@/api/teacher'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
import total from '@/assets/address/city'
import TeacherTagDialog from './components/TeacherTagDialog'
import TeacherOtherNews from './components/TeacherOtherNews'
import Tinymce from '@/components/Tinymce'
const rankOptions = [
  {
    label: '助教',
    value: '1',
  },
  {
    label: '中级',
    value: '2',
  },
  {
    label: '副高',
    value: '3',
  },
  {
    label: '正高',
    value: '4',
  },
]
const qualificationOptions = [
  {
    label: '未知',
    value: 0,
  },
  {
    label: '初中',
    value: 1,
  },
  {
    label: '高中',
    value: 2,
  },
  {
    label: '大专',
    value: 3,
  },
  {
    label: '本科',
    value: 4,
  },
  {
    label: '研究生',
    value: 5,
  },
  {
    label: '硕士',
    value: 6,
  },
  {
    label: '博士',
    value: 7,
  },
  {
    label: '博士后',
    value: 8,
  },
]
export default {
  name: 'TeacherAdd',
  components: { UploadImage, TeacherTagDialog, TeacherOtherNews, Tinymce, UploadFile },
  data() {
    // var checkEmail = (rule, value, callback) => {
    //   const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    //   if (!value) {
    //     return callback(new Error('邮箱不能为空'))
    //   }
    //   setTimeout(() => {
    //     if (mailReg.test(value)) {
    //       callback()
    //     } else {
    //       callback(new Error('请输入正确的邮箱格式'))
    //     }
    //   }, 100)
    // }
    return {
      rules: {
        // email: [{ required: true, validator: checkEmail, trigger: 'blur' }],
        teacherName: [{ required: true, message: '请输入讲师姓名', trigger: 'blur' }],
        teacherPicList: [{ required: true, message: '请上传讲师头像', trigger: 'change' }],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        // age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
        // birthday: [{ required: true, message: '请选择生日', trigger: 'change' }],
        teacherMajorIdList: [{ required: true, message: '请选择讲师分类', trigger: 'change' }],
        teacherTagIdList: [{ required: true, message: '请选择专长标签', trigger: 'change' }],
        // teacherQualification: [{ required: true, message: '请选择最高学历', trigger: 'change' }],
        // college: [{ required: true, message: '请输入毕业院校', trigger: 'blur' }],
        addressCode: [{ required: true, message: '请选择常驻地区', trigger: 'change' }],
        // title: [{ required: true, message: '请输入讲师称号', trigger: 'blur' }],
        teacherDetails: [{ required: true, message: '请输入简历描述', trigger: 'blur' }],
      },
      total,
      form: {
        address: {},
        addressCode: [],
        teacherTagIdList: [],
        accessLevel: 'PRIVATE',
        lowestOffer: '',
        highestOffer: '',
      },
      loading: false,
      teachers: [],
      teacherId: '',
      rankOptions,
      qualificationOptions,
      majorldOptions: [],
      teacherTagList: [],
      teacherOtherInfo: {},
    }
  },
  computed: {
    ...mapState(['step']),
  },
  beforeDestroy() {
    this.stepPage(2)
  },
  mounted() {
    this.getMajorTree()
    this.teacherId = this.$route.params.teacherId
    if (this.teacherId) this.getTeacherInfo()
  },
  methods: {
    ...mapMutations({ stepPage: 'SET_STEP' }),
    async getTeacherInfo() {
      const [res, err] = await to(teacherDetail({ teacherId: this.teacherId }))
      if (err) return this.$message.warning(err.msg)
      this.teacherOtherInfo = res.data.teacherOtherInfo
      const { province, city, teacherPicList } = res.data.teacherBaseInfo
      this.form = {
        ...res.data.teacherBaseInfo,
        addressCode: [province, city],
        teacherPicList: teacherPicList[0],
      }
      this.teacherTagList = res.data.teacherBaseInfo.teacherTagList
      this.form.highestOffer = String(this.form.highestOffer)
      this.form.lowestOffer = String(this.form.lowestOffer)
      console.log(this.form)
    },
    price(val) {
      if (val == 1) {
        if (!Number.isInteger(Number(this.form.lowestOffer)) || this.form.lowestOffer.length > 6) {
          this.$message.warning('请输入小于6位数的整数')
        }
      } else {
        if (!Number.isInteger(Number(this.form.highestOffer)) || this.form.lowestOffer.length > 6) {
          this.$message.warning('请输入小于6位数的整数')
        }
      }
    },
    checkEmail() {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      setTimeout(() => {
        if (!mailReg.test(this.form.email)) {
          this.$message.warning('请输入正确的邮箱格式')
        }
      }, 100)
    },
    back(val) {
      this.$nextTick(() => {
        this.$refs.uploadImage.setVal(val.teacherPicList)
      })
    },
    selectTag() {
      this.$refs.teacherTagDialog.show(this.teacherTagList)
    },
    tagChange({ teacherTagList, teacherTagIdList }) {
      if (teacherTagIdList.length) {
        this.$refs.teacherTag.clearValidate()
      }
      this.form.teacherTagIdList = teacherTagIdList
      this.teacherTagList = teacherTagList
    },
    removeTag(item) {
      const i = this.form.teacherTagIdList.indexOf(item.tagId)
      this.teacherTagList.splice(i, 1)
      this.form.teacherTagIdList.splice(i, 1)
    },
    async getMajorTree() {
      const [res, err] = await to(getTeacherTypeSelect({}))
      if (err) return this.$message.warning(err.msg)
      this.majorldOptions = res.data
    },
    handleAddressChange(value) {
      // console.log(value)
      this.form.province = value[0]
      this.form.city = value[1]
      // this.$set(
      //   this.form.address,
      //   'province',
      //   this.$refs.myCascader.getCheckedNodes()[0].pathLabels[0],
      // )
      // this.$set(this.form.address, 'city', this.$refs.myCascader.getCheckedNodes()[0].pathLabels[1])
    },
    submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          console.log(this.form.lowestOffer, this.form.highestOffer)
          if (this.form.lowestOffer == '' || this.form.highestOffer == '') {
            this.$message.warning('授课报价不能为空')
          } else {
            this.stepPage(1)
          }
        }
      })
      console.log(this.form)
      // this.teacherId ? this.update() : this.create()
    },
    create() {
      console.log(this.form)
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(
            teacherCreate({
              ...this.form,
              teacherPicList: [this.form.teacherPicList],
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          this.$router.push('/teacher/list')
        }
      })
    },
    update() {
      console.log(this.form)
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          this.form.province = this.form.addressCode[0]
          this.form.city = this.form.addressCode[1]
          const [, err] = await to(
            teacherUpdate({
              ...this.form,
              teacherPicList: [this.form.teacherPicList],
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('修改成功')
          this.$router.push('/teacher/list')
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-add {
  .coursePrice {
    width: 120px !important;
  }
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 120px;
    .el-upload {
      width: 120px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 120px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
  .teachers {
    li {
      box-sizing: border-box;
      float: left;
      position: relative;
      cursor: pointer;
      min-width: 80px;
      padding: 0 13px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      color: #333333;
      margin-right: 25px;
      margin-bottom: 10px;
      .close {
        position: absolute;
        width: 14px;
        height: 14px;
        right: -5px;
        top: -5px;
      }
    }
  }
}
</style>
