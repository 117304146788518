<!--
 * @Description: 选择专长
 * @Author: xiawenlong
 * @Date: 2021-05-18 15:45:51
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-18 20:14:32
-->
<template>
  <el-dialog :close-on-click-modal="false" class="teacher-tag" :visible.sync="dialogVisible">
    <div class="header">
      <div class="title">选择专长 <span>最多选择5个</span></div>
      <div class="close-icon" @click="dialogVisible = false">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="container">
      <div v-for="item in tagList" :key="item.tagId" class="type-item">
        <div class="title">{{ item.tagTypeName }}</div>
        <div class="tag-list">
          <div
            v-for="tag in item.children"
            :key="tag.tagId"
            :class="[
              'tag',
              { active: selectTagIds.includes(tag.tagId) },
              { disable: selectTagIds.length >= 5 && !selectTagIds.includes(tag.tagId) },
            ]"
            @click="select(tag)"
          >
            {{ tag.tagName }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getTeacherTagSelect } from '@/api/teacher'
import to from 'await-to'
export default {
  name: 'TeacherTag',
  data() {
    return {
      dialogVisible: false,
      tagList: [],
      selectTagIds: [],
      selectTags: [],
    }
  },
  methods: {
    async show(list) {
      this.dialogVisible = true
      await this.getTeacherTag()
      this.selectTags = list
      this.selectTagIds = list.map(e => e.tagId)
    },
    async getTeacherTag() {
      const [res, err] = await to(getTeacherTagSelect())
      if (err) return this.$message.warning(err.msg)
      this.tagList = res.data
    },
    select(e) {
      if (this.selectTagIds.includes(e.tagId)) {
        this.selectTags.splice(this.selectTagIds.indexOf(e.tagId), 1)
        return this.selectTagIds.splice(this.selectTagIds.indexOf(e.tagId), 1)
      }
      if (this.selectTagIds.length >= 5) {
        return this.$message.warning('最多选择5个')
      }
      this.selectTagIds.push(e.tagId)
      this.selectTags.push(e)
    },
    submit() {
      this.$emit('tagChange', {
        teacherTagList: this.selectTags,
        teacherTagIdList: this.selectTagIds,
      })
      this.dialogVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-tag {
  ::v-deep.el-dialog {
    position: relative;
    width: 1058px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .header {
      width: 100%;
      height: 54px;
      background: #f8f8f8;
      .title {
        font-size: 20px;
        line-height: 54px;
        font-weight: 500;
        color: #333333;
        padding-left: 30px;
        span {
          margin-left: 15px;
          font-size: 14px;
          color: #999999;
        }
      }
      .close-icon {
        width: 20px;
        line-height: 20px;
        overflow: hidden;
        z-index: 99;
        cursor: pointer;
        position: absolute;
        right: 17px;
        top: 17px;
        font-size: 20px;
        font-weight: bold;
        color: #8c8c8c;
      }
    }
    .container {
      box-sizing: border-box;
      height: 600px;
      overflow-y: scroll;
      padding: 22px 34px 35px;
      box-sizing: border-box;
      .type-item {
        .title {
          font-size: 22px;
          font-weight: 700;
          color: #333333;
          margin-bottom: 20px;
        }
        .tag {
          cursor: pointer;
          display: inline-block;
          height: 36px;
          line-height: 36px;
          padding: 0 28px;
          border: 1px solid #c2c2c2;
          border-radius: 19px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #333333;
          margin-right: 20px;
          margin-bottom: 20px;
          &.active {
            height: 36px;
            background: #ff7b33;
            border-radius: 18px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            border-color: #ff7b33;
          }
          &.disable {
            background: #f2f2f2;
            border: 1px solid #c2c2c2;
            color: #999999;
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 20px 0 35px;
      background-color: #fff;
      border-top: 1px solid #e2e2e2;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
